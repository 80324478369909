@font-face {
  font-family: "Changa One";
  src: url("fonts/ChangaOne-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body {
  background-color: #eeeeee;
  font-family: sans-serif;
  margin: 0;
}
canvas {
  display: block;
  margin: 20px auto 0;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #f5f2d0;
}

.container {
  min-width: 440px;
  position: relative;
  min-height: 600px;
}

#current-scale {
  font-weight: bold;
  color: orange;
}
#data-display {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

div {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  margin: 0 auto;
  margin-top: 30px;
}

.enharmonic-wrapper {
  margin: 0;
}
.game-header-wrap {
  display: flex;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

#game-heading {
  font-size: 4rem;
  text-align: center;
  line-height: 1;
  font-family: "Changa One", sans-serif;
  max-width: 400px;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(60deg, #3c3a88, blue);
  color: transparent;
  margin: 0;
}
input[type="radio"] {
  margin-right: 5px;
}

label {
  color: black;
  font-weight: 600;
  font-size: 2rem;
}
.mode-select,
.tonic-select {
  width: 140px;
  text-align: center;
  font-weight: 900;
  font-size: 1.1rem;
}

.mode-wrapper,
.tonic-wrapper {
  display: flex;
  padding: 0;
  max-width: 375x;
  gap: 20px;
  justify-content: center;
}

.enharmonic-selector-buttons,
.speed-selector-buttons {
  display: flex;
  justify-content: center;
  margin: 0;
  padding-top: 10px;
  font-weight: 400;
}

.enharmonic-selector-buttons input,
.speed-selector-buttons input {
  margin-right: 20px;
}

.enharmonic-selector-buttons label,
.speed-selector-buttons label {
  font-weight: 400;
  font-size: 1.3rem;
}
.enharmonic-selector,
.speed-selector {
  font-size: 1.4rem;
}

.enharmonic-wrapper,
.speed-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.speed-wrapper {
  margin-top: 80px;
}

.options-display {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 90%;
}

.options-display.hidden {
  display: none;
}

p {
  margin: 5px 0;
}

.play-again-btn:hover {
  cursor: pointer;
}

#score {
  font-size: 28px;
}

.scale-score-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  align-items: center;
  width: 100%;
}
.selected-scale {
  text-align: center;
  font-size: 1.75rem;
  font-weight: 900;
  margin: 0 auto;
  padding: 0;
}
.play-again-btn,
.start-game-btn {
  background-color: #3c3a88;
  color: #fff;
  border-radius: 8px;
  padding: 0.75em 1.5em;
  font-size: 1.1rem;
  min-width: 120px;
  width: 160px;
  border: 4px solid #e3d865;
  transition: all 100ms ease-in;
  margin-top: 40px;
}

.play-again-btn {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.start-game-btn:hover {
  cursor: pointer;
}

#tonic-note {
  font-weight: bold;
  color: tan;
}

.score-icon {
  width: 24px;
}
#scale-notes {
  color: black;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

@media (min-width: 960px) {
  .container {
    width: 75%;
    max-width: 1200px;
    position: relative;
  }
  .options-display {
    width: 100%;
  }
  .scale-score-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0 auto;
    align-items: center;
    width: 440px;
  }
}
